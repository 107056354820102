/* Basic setup to compile landing page */
/* Styles */
html,
body {
  height: 100%;
}

body{
  height: 100%;
  text-align:center;
}

.placeholder { color: #aaa; }


/* Text Styles */
body{
  font-family: Verdana, Geneva, sans-serif	    }

h1, h2, h3, h4, h5, h6{
  font-family: Verdana, Geneva, sans-serif	    }

body{
  color:#16f093;
}



h1, h2, h3, h4, h5, h6{
  color:#444444;
}


a, a:visited, a:hover, a:active{
  color:#27AE60;
}

/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter, div.aligncenter {
  display:block;
  margin: 5px auto 5px auto;
}

.alignright {
  float:right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float:left;
  margin: 5px 20px 20px 0;
}

.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

a img.alignright {
  float:right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float:left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%; /* Image does not overflow the content area */
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin:0;
  max-width: 98.5%;
  padding:0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size:11px;
  line-height:17px;
  margin:0;
  padding:0 4px 5px;
}